<template>
  <div>
    <div v-if="getLoaded()">
      <div class="data-control">
        <Search placeholder="ค้นชื่อผู้รับเหมา,รหัสโครงการ" />
      </div>

      <div v-if="auction.row==0">
        <EmptyBlockComp />
      </div>
      <section v-else class="data-list">
        <table class="tb-data">
          <colgroup>>
            <col />
            <col class="col-budget" />
            <col class="col-code"/>
            <col class="col-project"/>
            <col class="col-date" />
          </colgroup>
          <thead>
            <tr>
              <th>ชื่อผู้เสนอราคา</th>
              <th>งบประมาณ</th>
              <th>รหัสโครงการ</th>
              <th>ชื่อโครงการ</th>
              <th>วันที่</th>
            </tr>
          </thead>
  
          <tbody>
            <tr v-for="(item, i) in auction.result" :key="i">
              <td>
                <router-link :to="getViewRoute(item)" class="member-name">
                  {{ setMemberName(item) }}
                </router-link>
                <ul class="item-action">
                  <li>
                    <router-link :to="getViewRoute(item)" class="edit">ตรวจสอบใบเสนอราคา</router-link>
                  </li>
                </ul>
              </td>
              <td>{{ item.budget | formatNumber('.00') }}</td>
              <td>
                <router-link :to="'/project/edit/' + item.PID" title="view project" class="item-code">{{ item.project_code }}</router-link>
              </td>
              <td>{{ item.project_name }}</td>
              <td>{{ item.date_modified | formatDate }}</td>
            </tr>
          </tbody>
  
          <tfoot>
            <tr>
              <th>ชื่อผู้เสนอราคา</th>
              <th>งบประมาณ</th>
              <th>รหัสโครงการ</th>
              <th>ชื่อโครงการ</th>
              <th>วันที่</th>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';

import Search         from '@/components/common/Search.vue'
import EmptyBlockComp from '@/components/common/EmptyBlockComp.vue'

export default {
  name: 'AuctionRequest',
  data() {
    return {
      auction: {
        total: 0
      },
      
    }
  },
  components: {
    EmptyBlockComp,
    Search,
  },
  watch:{
    $route() {
      this.loadPage();
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'Auction Requests');
    this.$store.commit('setHeadTitle', 'Auction Requests');
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage() {
      this.$store.commit('setLoaderShow', true);

      let queryParams = {};
      if(this.$route.query.q!==undefined) {
        queryParams.q = this.$route.query.q;
      }

      apiRequest
        .get( '/auction', { params: queryParams })
        .then( (res) => {
          this.auction = res.data;
          this.auction.total = Number(this.auction.total);

          this.$store.commit('setLoaderShow', false);
        })
      this.loaded = true;
    },
    
    getViewRoute(data) {
      return '/project/auction-request/view/'+data.PID+'/'+data.MID;
    },

    setMemberName(data) {
      let fullname = data.first_name+' '+data.last_name;
      return (data.business_type==9) ? data.company_name+' ('+fullname+')' : fullname;
    }
  }
}
</script>


<style scoped>
.tb-data .col-code  {width: 110px;}
.tb-data .col-project  {width: 30%;}
.tb-data .col-budget {width: 12%;}
.tb-data .col-date  {width: 15%;}

.member-name {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
}
.item-action .approve { color: #369; }

@media all and (max-width: 640px) {
  .tb-data {min-width: 860px;}
}
</style>